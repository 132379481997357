<template>
    <div class="modal-recommend">
        <div class="top-nav flex-row">
            <i @click="$emit('close')" class="zmdi zmdi-close" />
            <div class="title" v-html="$translate('RECOMMEND_TITLE')" />
        </div>
        <div class="scroll-area single-recommend">
            <div class="payload">
                <div class="textarea-container">
                    <div class="section-title-mob m-t-24 m-b-16" v-html="$translate('APPEAL_MESSAGE')" />
                    <TextareaWithAutoTexts v-model="recommendMessage" class="bottom" />
                </div>

                <div class="photo-container">
                    <div class="section-title-mob flex-row items-center m-b-16">
                        <span class="flex-wrap" v-html="$translate('CHOOSE_PHOTO')" />
                        <span
                            class="desc"
                            :class="{ 'c-primary': twoPhotosChecked }"
                            v-html="`(${$translate('CHOOSE_PHOTO_DESC')})`"
                        />
                    </div>
                    <div class="photos" v-if="chatUser">
                        <div
                            class="img-profile"
                            @click="onClickPhoto(photo)"
                            v-lazy:background-image="photo.url"
                            :key="idx"
                            v-for="(photo, idx) in chatUser.photos"
                        >
                            <Checkbox class="white" v-model="photo.checked" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <button @click="onSend" :class="{ disabled }">제안하기</button>
        </div>
    </div>
</template>
<script>
import suggestService from '@/services/suggest'

export default {
    name: 'ModalRecommendMob',
    props: {
        options: Object,
    },
    data: () => ({
        recommendMessage: null,
        chatUser: {},
        recommendBody: null,
    }),
    computed: {
        recommendedUser() {
            return this.options.recommendedUser
        },
        nickname() {
            return this.recommendedUser.nickname || this.recommendedUser.profile.nickname
        },
        disabled() {
            return !this.recommendMessage || !this.twoPhotosChecked
        },
        twoPhotosChecked() {
            return (this.chatUser.photos || []).filter(p => p.checked).length === 2
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            // this.recommendMessage = this.$translate('RECOMMEND_DEFAULT_MESSAGE').replace(/%s/g, this.nickname)
            this.chatUser = await this.$store.dispatch('loadUserDetail', { userId: this.$store.getters.chat.user.id })
            this.recommendBody =
                this.options && this.recommendedUser
                    ? this.$translate('RECOMMEND_BODY')
                          .replace(/%s/, this.nickname)
                          .replace(/%s/, this.$nameOrNick(this.chatUser))
                          .replace(/%s/, this.nickname)
                    : null

            const addCheckedToPhotos = () => {
                ;(this.chatUser.photos || []).forEach((p, idx) => this.$set(p, 'checked', idx < 2))
            }
            addCheckedToPhotos()
        },
        onClickPhoto(photo) {
            if (!photo.checked && this.twoPhotosChecked) {
                this.$toast.error('CHOOSE_PHOTO_DESC')
                return
            }

            photo.checked = !photo.checked
        },
        async onSend() {
            if (this.disabled) {
                this.$toast.error('ERROR_SPREAD_PROFILE_SUGGEST')
                return
            }

            this.onConfirmSendRequests()
        },
        async onConfirmSendRequests() {
            if (!this.twoPhotosChecked) {
                return
            }

            try {
                this.$loading(true)
                const photos = this.chatUser.photos.filter(p => p.checked)
                const data = await suggestService.request({
                    user_id: this.recommendedUser.id,
                    source_id: this.chatUser.id,
                    message: this.recommendMessage,
                    referred: 'recommend_user',
                    photo1_id: photos[0].id,
                    photo2_id: photos[1].id,
                })
                this.$store.commit('markAsIntroducedTo', [this.recommendedUser.id])
                this.$toast.success(data.msg)
                this.$emit('close', 1)
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$loading(false)
        },
    },
}
</script>
<style scoped lang="scss">
::v-deep .textarea {
    height: 240px !important;
}
</style>
